<template>
  <!-- Terms & Conditions Area -->
  <div class="inner-terms-banner">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-10">
          <div class="section-heading-14 text-center">
            <h2>Privacy Policy</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Content Area -->
  <div class="terms-condition-area">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-7 col-xl-8 col-lg-9">
          <div class="terms-con-content-1">
            <p>
              We respect your privacy and are committed to protecting it through
              our compliance with this privacy policy (“Policy”). This Policy
              describes the types of information we may collect from you or that
              you may provide (“Personal Information”) on the
              <a href="https://bfm-solutions.com" target="_blank"
                >bfm-solutions.com</a
              >
              website (“Website” or “Service”) and any of its related products
              and services (collectively, “Services”), and our practices for
              collecting, using, maintaining, protecting, and disclosing that
              Personal Information. It also describes the choices available to
              you regarding our use of your Personal Information and how you can
              access and update it.
            </p>
            <p>
              This Policy is a legally binding agreement between you (“User”,
              “you” or “your”) and BFM Solutions GmbH (“BFM Solutions GmbH”,
              “we”, “us” or “our”). If you are entering into this agreement on
              behalf of a business or other legal entity, you represent that you
              have the authority to bind such entity to this agreement, in which
              case the terms “User”, “you” or “your” shall refer to such entity.
              If you do not have such authority, or if you do not agree with the
              terms of this agreement, you must not accept this agreement and
              may not access and use the Website and Services. By accessing and
              using the Website and Services, you acknowledge that you have
              read, understood, and agree to be bound by the terms of this
              Policy. This Policy does not apply to the practices of companies
              that we do not own or control, or to individuals that we do not
              employ or manage.
            </p>
          </div>
          <div class="license-area">
            <h4>Collection of information</h4>
            <p>
              Our top priority is customer data security and, as such, we
              exercise the no logs policy. We may process only minimal user
              data, only as much as it is absolutely necessary to maintain the
              Website and Services. Information collected automatically is used
              only to identify potential cases of abuse and establish
              statistical information regarding the usage and traffic of the
              Website and Services. This statistical information is not
              otherwise aggregated in such a way that would identify any
              particular user of the system.
            </p>
          </div>
          <div class="license-area">
            <h4>Privacy of children</h4>
            <p>
              We do not knowingly collect any Personal Information from children
              under the age of 13. If you are under the age of 13, please do not
              submit any Personal Information through the Website and Services.
              If you have reason to believe that a child under the age of 13 has
              provided Personal Information to us through the Website and
              Services, please contact us to request that we delete that child’s
              Personal Information from our Services.
            </p>
            <p>
              We encourage parents and legal guardians to monitor their
              children’s Internet usage and to help enforce this Policy by
              instructing their children never to provide Personal Information
              through the Website and Services without their permission. We also
              ask that all parents and legal guardians overseeing the care of
              children take the necessary precautions to ensure that their
              children are instructed to never give out Personal Information
              when online without their permission.
            </p>
          </div>
          <div class="skills-qualification">
            <h4>Use and processing of collected information</h4>
            <p>
              We act as a data controller and a data processor in terms of the
              GDPR when handling Personal Information, unless we have entered
              into a data processing agreement with you in which case you would
              be the data controller and we would be the data processor.
            </p>
            <p>
              Our role may also differ depending on the specific situation
              involving Personal Information. We act in the capacity of a data
              controller when we ask you to submit your Personal Information
              that is necessary to ensure your access and use of the Website and
              Services. In such instances, we are a data controller because we
              determine the purposes and means of the processing of Personal
              Information and we comply with data controllers’ obligations set
              forth in the GDPR.
            </p>
            <p>
              We act in the capacity of a data processor in situations when you
              submit Personal Information through the Website and Services. We
              do not own, control, or make decisions about the submitted
              Personal Information, and such Personal Information is processed
              only in accordance with your instructions. In such instances, the
              User providing Personal Information acts as a data controller in
              terms of the GDPR.
            </p>
            <p>
              In order to make the Website and Services available to you, or to
              meet a legal obligation, we may need to collect and use certain
              Personal Information. If you do not provide the information that
              we request, we may not be able to provide you with the requested
              products or services. Any of the information we collect from you
              may be used for the following purposes:
            </p>
            <ul>
              <li>
                <span>Enforce terms and conditions and policies</span>
              </li>
              <li>Protect from abuse and malicious users</li>
              <li>Respond to legal requests and prevent harm</li>
              <li>Run and operate the Website and Services</li>
            </ul>
            <br />
            <p>
              Processing your Personal Information depends on how you interact
              with the Website and Services, where you are located in the world
              and if one of the following applies: (i) you have given your
              consent for one or more specific purposes; this, however, does not
              apply, whenever the processing of Personal Information is subject
              to European data protection law; (ii) provision of information is
              necessary for the performance of an agreement with you and/or for
              any pre-contractual obligations thereof; (iii) processing is
              necessary for compliance with a legal obligation to which you are
              subject; (iv) processing is related to a task that is carried out
              in the public interest or in the exercise of official authority
              vested in us; (v) processing is necessary for the purposes of the
              legitimate interests pursued by us or by a third party.
            </p>
            <p>
              We rely on the following legal bases as defined in the GDPR upon
              which we collect and process your Personal Information:
            </p>
            <ul>
              <li>
                <span>Personal Information is already publicly available</span>
              </li>
            </ul>
            <br />
            <p>
              Note that under some legislations we may be allowed to process
              information until you object to such processing by opting out,
              without having to rely on consent or any other of the legal bases
              above. In any case, we will be happy to clarify the specific legal
              basis that applies to the processing, and in particular whether
              the provision of Personal Information is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>
          </div>
          <div class="license-area">
            <h4>Disclosure of information</h4>
            <p>
              Depending on the requested Services or as necessary to complete
              any transaction or provide any Service you have requested, we may
              share your information with our affiliates, contracted companies,
              and service providers (collectively, “Service Providers”) we rely
              upon to assist in the operation of the Website and Services
              available to you and whose privacy policies are consistent with
              ours or who agree to abide by our policies with respect to
              Personal Information. We will not share any personally
              identifiable information with third parties and will not share any
              information with unaffiliated third parties.
            </p>
            <p>
              Service Providers are not authorized to use or disclose your
              information except as necessary to perform services on our behalf
              or comply with legal requirements. Service Providers are given the
              information they need only in order to perform their designated
              functions, and we do not authorize them to use or disclose any of
              the provided information for their own marketing or other
              purposes.
            </p>
          </div>
          <div class="license-area">
            <h4>Retention of information</h4>
            <p>
              We will retain and use your Personal Information for the period
              necessary to comply with our legal obligations, to enforce our
              agreements, resolve disputes, and unless a longer retention period
              is required or permitted by law up to a maximum of 1 months.
            </p>
            <p>
              We may use any aggregated data derived from or incorporating your
              Personal Information after you update or delete it, but not in a
              manner that would identify you personally. Once the retention
              period expires, Personal Information shall be deleted. Therefore,
              the right to access, the right to erasure, the right to
              rectification, and the right to data portability cannot be
              enforced after the expiration of the retention period.
            </p>
          </div>
          <div class="license-area">
            <h4>Transfer of information</h4>
            <p>
              Depending on your location, data transfers may involve
              transferring and storing your information in a country other than
              your own. However, this will not include countries outside the
              European Union and European Economic Area. If any such transfer
              takes place, you can find out more by checking the relevant
              sections of this Policy or inquire with us using the information
              provided in the contact section.
            </p>
          </div>
          <div class="license-area">
            <h4>Data protection rights under the GDPR</h4>
            <p>
              If you are a resident of the European Economic Area (“EEA”), you
              have certain data protection rights and we aim to take reasonable
              steps to allow you to correct, amend, delete, or limit the use of
              your Personal Information. If you wish to be informed what
              Personal Information we hold about you and if you want it to be
              removed from our systems, please contact us. In certain
              circumstances, you have the following data protection rights:
            </p>
            <p>
              (i) You have the right to withdraw consent where you have
              previously given your consent to the processing of your Personal
              Information. To the extent that the legal basis for our processing
              of your Personal Information is consent, you have the right to
              withdraw that consent at any time. Withdrawal will not affect the
              lawfulness of processing before the withdrawal.
            </p>
            <p>
              (ii) You have the right to learn if your Personal Information is
              being processed by us, obtain disclosure regarding certain aspects
              of the processing, and obtain a copy of your Personal Information
              undergoing processing.
            </p>
            <p>
              (iii) You have the right to verify the accuracy of your
              information and ask for it to be updated or corrected. You also
              have the right to request us to complete the Personal Information
              you believe is incomplete.
            </p>
            <p>
              (iv) You have the right to object to the processing of your
              information if the processing is carried out on a legal basis
              other than consent. Where Personal Information is processed for
              the public interest, in the exercise of an official authority
              vested in us, or for the purposes of the legitimate interests
              pursued by us, you may object to such processing by providing a
              ground related to your particular situation to justify the
              objection.
            </p>
            <p>
              (v) You have the right, under certain circumstances, to restrict
              the processing of your Personal Information. These circumstances
              include: the accuracy of your Personal Information is contested by
              you and we must verify its accuracy; the processing is unlawful,
              but you oppose the erasure of your Personal Information and
              request the restriction of its use instead; we no longer need your
              Personal Information for the purposes of processing, but you
              require it to establish, exercise or defend your legal claims; you
              have objected to processing pending the verification of whether
              our legitimate grounds override your legitimate grounds. Where
              processing has been restricted, such Personal Information will be
              marked accordingly and, with the exception of storage, will be
              processed only with your consent or for the establishment, to
              exercise or defense of legal claims, for the protection of the
              rights of another natural, or legal person or for reasons of
              important public interest.
            </p>
            <p>
              (vi) You have the right, under certain circumstances, to obtain
              the erasure of your Personal Information from us. These
              circumstances include: the Personal Information is no longer
              necessary in relation to the purposes for which it was collected
              or otherwise processed; you withdraw consent to consent-based
              processing; you object to the processing under certain rules of
              applicable data protection law; the processing is for direct
              marketing purposes; and the personal data have been unlawfully
              processed. However, there are exclusions of the right to erasure
              such as where processing is necessary: for exercising the right of
              freedom of expression and information; for compliance with a legal
              obligation; or for the establishment, to exercise or defense of
              legal claims.
            </p>
            <p>
              (vii) You have the right to receive your Personal Information that
              you have provided to us in a structured, commonly used, and
              machine-readable format and, if technically feasible, to have it
              transmitted to another controller without any hindrance from us,
              provided that such transmission does not adversely affect the
              rights and freedoms of others.
            </p>
            <p>
              (viii) You have the right to complain to a data protection
              authority about our collection and use of your Personal
              Information. If you are not satisfied with the outcome of your
              complaint directly with us, you have the right to lodge a
              complaint with your local data protection authority. For more
              information, please contact your local data protection authority
              in the EEA. This provision is applicable provided that your
              Personal Information is processed by automated means and that the
              processing is based on your consent, on a contract which you are
              part of, or on pre-contractual obligations thereof.
            </p>
          </div>
          <div class="license-area">
            <h4>How to exercise your rights</h4>
            <p>
              Any requests to exercise your rights can be directed to us through
              the contact details provided in this document. Please note that we
              may ask you to verify your identity before responding to such
              requests. Your request must provide sufficient information that
              allows us to verify that you are the person you are claiming to be
              or that you are the authorized representative of such person. If
              we receive your request from an authorized representative, we may
              request evidence that you have provided such an authorized
              representative with power of attorney or that the authorized
              representative otherwise has valid written authority to submit
              requests on your behalf.
            </p>
            <p>
              You must include sufficient details to allow us to properly
              understand the request and respond to it. We cannot respond to
              your request or provide you with Personal Information unless we
              first verify your identity or authority to make such a request and
              confirm that the Personal Information relates to you.
            </p>
          </div>
          <div class="license-area">
            <h4>Cookies</h4>
            <p>
              Our Website and Services use “cookies” to help personalize your
              online experience. A cookie is a text file that is placed on your
              hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you, and can only be read by a web server in the
              domain that issued the cookie to you. You may learn more about
              cookies and how they work in
              <a
                href="https://www.websitepolicies.com/blog/cookies"
                target="_blank"
                >this guide</a
              >.
            </p>
            <p>
              We may use cookies to collect, store, and track information for
              security and personalization, and for statistical purposes. Please
              note that you have the ability to accept or decline cookies. Most
              web browsers automatically accept cookies by default, but you can
              modify your browser settings to decline cookies if you prefer.
            </p>
          </div>
          <div class="license-area">
            <h4>Data analytics</h4>
            <p>
              Our Website and Services may use third-party analytics tools that
              use cookies, web beacons, or other similar information-gathering
              technologies to collect standard internet activity and usage
              information. The information gathered is used to compile
              statistical reports on User activity such as how often Users visit
              our Website and Services, what pages they visit and for how long,
              etc. We use the information obtained from these analytics tools to
              monitor the performance and improve our Website and Services. We
              do not use third-party analytics tools to track or to collect any
              personally identifiable information of our Users and we will not
              associate any information gathered from the statistical reports
              with any individual User.
            </p>
          </div>
          <div class="license-area">
            <h4>Do Not Track signals</h4>
            <p>
              Some browsers incorporate a Do Not Track feature that signals to
              websites you visit that you do not want to have your online
              activity tracked. Tracking is not the same as using or collecting
              information in connection with a website. For these purposes,
              tracking refers to collecting personally identifiable information
              from consumers who use or visit a website or online service as
              they move across different websites over time. How browsers
              communicate the Do Not Track signal is not yet uniform. As a
              result, the Website and Services are not yet set up to interpret
              or respond to Do Not Track signals communicated by your browser.
              Even so, as described in more detail throughout this Policy, we
              limit our use and collection of your Personal Information.
            </p>
          </div>
          <div class="license-area">
            <h4>Links to other resources</h4>
            <p>
              The Website and Services contain links to other resources that are
              not owned or controlled by us. Please be aware that we are not
              responsible for the privacy practices of such other resources or
              third parties. We encourage you to be aware when you leave the
              Website and Services and to read the privacy statements of each
              and every resource that may collect Personal Information.
            </p>
          </div>
          <div class="license-area">
            <h4>Information security</h4>
            <p>
              We secure information you provide on computer servers in a
              controlled, secure environment, protected from unauthorized
              access, use, or disclosure. We maintain reasonable administrative,
              technical, and physical safeguards in an effort to protect against
              unauthorized access, use, modification, and disclosure of Personal
              Information in our control and custody. However, no data
              transmission over the Internet or wireless network can be
              guaranteed.
            </p>
            <p>
              Therefore, while we strive to protect your Personal Information,
              you acknowledge that (i) there are security and privacy
              limitations of the Internet which are beyond our control; (ii) the
              security, integrity, and privacy of any and all information and
              data exchanged between you and the Website and Services cannot be
              guaranteed; and (iii) any such information and data may be viewed
              or tampered with in transit by a third party, despite best
              efforts.
            </p>
          </div>
          <div class="license-area">
            <h4>Data breach</h4>
            <p>
              In the event we become aware that the security of the Website and
              Services has been compromised or Users’ Personal Information has
              been disclosed to unrelated third parties as a result of external
              activity, including, but not limited to, security attacks or
              fraud, we reserve the right to take reasonably appropriate
              measures, including, but not limited to, investigation and
              reporting, as well as notification to and cooperation with law
              enforcement authorities. In the event of a data breach, we will
              make reasonable efforts to notify affected individuals if we
              believe that there is a reasonable risk of harm to the User as a
              result of the breach or if notice is otherwise required by law.
              When we do, we will post a notice on the Website.
            </p>
          </div>
          <div class="license-area">
            <h4>Changes and amendments</h4>
            <p>
              We reserve the right to modify this Policy or its terms related to
              the Website and Services at any time at our discretion. When we
              do, we will revise the updated date at the bottom of this page,
              post a notification on the main page of the Website. We may also
              provide notice to you in other ways at our discretion, such as
              through the contact information you have provided.
            </p>
            <p>
              An updated version of this Policy will be effective immediately
              upon the posting of the revised Policy unless otherwise specified.
              Your continued use of the Website and Services after the effective
              date of the revised Policy (or such other act specified at that
              time) will constitute your consent to those changes. However, we
              will not, without your consent, use your Personal Information in a
              manner materially different than what was stated at the time your
              Personal Information was collected.
            </p>
          </div>
          <div class="license-area">
            <h4>Acceptance of this policy</h4>
            <p>
              You acknowledge that you have read this Policy and agree to all
              its terms and conditions. By accessing and using the Website and
              Services and submitting your information you agree to be bound by
              this Policy. If you do not agree to abide by the terms of this
              Policy, you are not authorized to access or use the Website and
              Services.
            </p>
          </div>
          <div class="license-area">
            <h4>Contacting us</h4>
            <p>
              If you have any questions, concerns, or complaints regarding this
              Policy, the information we hold about you, or if you wish to
              exercise your rights, we encourage you to contact us using the
              details below:
            </p>
            <p>
              <img src="/images/email.png" style="height: 22px" />

              <br />Neuheimstr 34b, CH-8853 Lachen, Switzerland
            </p>
            <p>
              We will attempt to resolve complaints and disputes and make every
              reasonable effort to honor your wish to exercise your rights as
              quickly as possible and in any event, within the timescales
              provided by applicable data protection laws.
            </p>
            <p>This document was last updated on August 1, 2021</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PrivacyPolicy",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
