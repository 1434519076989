<template>
  <!-- Footer Area -->
  <footer class="footer-l10">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="footer-border-top"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-5 col-sm-9">
          <div class="row">
            <div class="col-xl-9 col-lg-10 col-md-8">
              <!-- <a href="#"><img src="./images/logos/logo-paste.png" alt="" class="footer-logo"></a> -->
              <div class="content">
                <p>Copyright © BFM Solutions GmbH 2021</p>
              </div>
              <div class="social-icons">
                <ul class="pl-0 list-unstyled d-flex align-items-end">
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="d-flex flex-column justify-content-center">
                    <a href="#"><i class="fab fa-google"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-7 col-md-12">
          <div class="row">
            <div class="col-lg-5 col-md-3 col-sm-4 col-xs-6">
              <div class="footer-widget">
                <p class="widget-title">Contact us</p>
                <ul class="widget-links pl-0 list-unstyled">
                  <p>
                    BFM Solutions GmbH<br />
                    Neuheimstr 34b<br />
                    CH-8853 Lachen<br />
                    Switzerland<br />
                    <img src="/images/email.png" style="height:19px"/>
                  </p>
                  <!-- <li><a href="#">Features</a></li>
                  <li><a href="#">Works</a></li>
                  <li><a href="#">Career</a></li> -->
                </ul>
              </div>
            </div>
            <div class="col-lg-5 col-md-5 col-sm-4 col-xs-6 pl-md-9 pl-6">
              <div class="footer-widget">
                <p class="widget-title">Support</p>
                <ul class="widget-links pl-0 list-unstyled">
                  <li>
                    <router-link to="/impressum">Impressum</router-link>
                  </li>
                  <li>
                    <router-link to="/privacypolicy"
                      >Privacy Policy</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link to="/termsandconditions"
                      >Terms & Conditions</router-link
                    >
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
