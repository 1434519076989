<template>
  <div class="site-wrapper overflow-hidden position-relative">
    <!-- Hero Area -->
    <div class="hero-area-l0 position-relative">
      <div class="container">
        <div class="row position-relative justify-content-center">
          <aos-vue
            class="col-xxl-5 col-xl-6 col-lg-7 col-md-10 order-lg-1 order-1"
            animation="fade-right"
            :delay="300"
            :duration="1000"
          >
            <div class="content">
              <h1>BFM Solutions</h1>
              <p>
                Software apps, mobile and web, making our world a more fun place
                to live.
              </p>
            </div>
          </aos-vue>
          <aos-vue
            class="
              offset-xxl-1
              col-xxl-6 col-xl-6 col-lg-5 col-md-8
              order-lg-1 order-0
            "
            animation="fade-left"
            :delay="300"
            :duration="1000"
          >
            <div class="">
              <img src="/images/l1-hero-img.png" alt="" class="w-100" />
            </div>
          </aos-vue>
        </div>
      </div>
    </div>
  </div>

  <!-- Content Area-1 -->
  <div class="content-area-l10-1">
    <div class="container">
      <div
        class="
          row
          align-items-center
          justify-content-lg-start justify-content-center
        "
      >
        <div
          class="col-lg-5 col-md-8"
          animation="fade-up"
          :delay="300"
          :duration="1000"
        >
          <div class="content-img pr-9 pl-13">
            <img src="images/l1/content-img1.svg" alt="" class="w-100" />
          </div>
        </div>
        <div
          class="offset-xxl-1 col-xxl-4 offset-xl-1 col-xl-5 col-lg-7 col-md-9"
          animation="fade-up"
          :delay="500"
          :duration="1000"
        >
          <div class="content section-heading-4">
            <h2>What we do</h2>
            <p>Need great apps?</p>
            <p>
              BFM Solutions GmbH. is an experienced Swiss tech company. <br />
              We offer high-quality mobile and web applications and services for
              individuals or small businesses.<br />
              Our developers and designers are well-versed in the latest
              technical tools and practices and our solutions are built to
              perform and align with the evolving technical environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HomeContent",
  components: {},
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
